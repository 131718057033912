<template>
  <b-col class="col-12 pt-5 px-3 p_b__3">
    <h4 class="mb-4 font-weight-normal text-muted">
      {{ $t("page.usage.title") }}
    </h4>
    <b-row class="no-gutters">
      <b-col class="col-12 col-md-6 col-lg-4">
        <b-card no-body class="p-3 h-100">
          <h6 class="mb-0 font-weight-normal text-uppercase">
            {{ $t("page.usage.subsriptionPlan") }}
          </h6>
          <hr class="mt-2 mb-3 mx-n3 divider" />
          <div class="text-uppercase font-weight-bold mb-4">
            {{ $t("page.usage.fullMedia") }}
          </div>
          <b-row class="no-gutters body__2 align-items-center">
            <b-col class="col-12 col-sm-6 text-uppercase text-muted pr-4">
              <div class="mb-1">
                {{ $t("page.usage.videos") }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <div class="mb-1 font-weight-bold">
                {{ itemsPurchased }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6 text-uppercase text-muted pr-4">
              <div class="mb-1">
                {{ $t("page.usage.minutesPerVideo") }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <div class="mb-1 font-weight-bold">
                {{
                  $t("page.usage.maxMinutesVideo", { total: minutesPerVideo })
                }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6 text-uppercase text-muted pr-4">
              <div class="mb-1">
                {{ $t("page.usage.respondentPerVideo") }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <div class="mb-1 font-weight-bold">
                {{ respondentPerVideo }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6 text-uppercase text-muted pr-4">
              <div class="mb-1">
                {{ $t("page.usage.targetSegmentation") }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <div class="mb-1 font-weight-bold">
                <b-icon icon="check2" font-scale="1.5" variant="green"></b-icon>
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6 text-uppercase text-muted pr-4">
              <div class="mb-1">
                {{ $t("page.usage.countrySelection") }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <div class="mb-1 font-weight-bold">
                <b-icon icon="check2" font-scale="1.5" variant="green"></b-icon>
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6 text-uppercase text-muted pr-4">
              <div class="mb-1">
                {{ $t("page.usage.cognitiveSurvey") }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <div class="mb-1 font-weight-bold">
                {{
                  $t("page.usage.totalQuestions", { total: questionsPerVideo })
                }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-6 pl-0 pl-md-3 mt-3 mt-md-0">
        <b-card no-body class="p-3 h-100">
          <h6 class="mb-0 font-weight-normal text-uppercase">
            {{ $t("page.usage.accountBalance") }}
          </h6>
          <hr class="mt-2 mb-3 mx-n3 divider" />
          <div class="body__2 font-weight-bold text-uppercase">
            {{ $t("page.usage.videoAnalized") }}
          </div>
          <div class="body__2 font-weight-bold">
            {{ itemsUsed }}/{{ itemsPurchased }}
          </div>
          <div class="mt-3 mb-5">
            <b-progress
              :value="totalVideoAnalizedPercent"
              variant="green"
              class="rounded-0"
            ></b-progress>
          </div>
          <div class="body__2 font-weight-bold text-uppercase">
            {{ $t("page.usage.videoInProgress") }}
          </div>
          <div class="body__2 font-weight-bold">{{ totalInProgressVideo }}</div>
          <b-col class="col-auto px-0 mt-3">
            <b-button
              size="sm"
              variant="outline-light"
              @click="goToProjectPage"
            >
              <b-icon icon="eye" variant="dark" class="mr-2"></b-icon>
              <span class="body__2 text-muted">{{
                $t("page.usage.viewProjects")
              }}</span>
            </b-button>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  computed: {
    clientInfo() {
      return this.$store.getters["auth/getUserAccount"];
    },
    itemsPurchased() {
      return this.clientInfo.itemsPurchased;
    },
    minutesPerVideo() {
      return 2;
    },
    respondentPerVideo() {
      return 150;
    },
    questionsPerVideo() {
      return 5;
    },
    itemsUsed() {
      return this.clientInfo.itemsUsed;
    },
    totalVideoAnalizedPercent() {
      return this.itemsPurchased > 0
        ? (this.itemsUsed / this.itemsPurchased) * 100
        : 0;
    },
    totalInProgressVideo() {
      return this.clientInfo.itemsInProgress;
    }
  },
  methods: {
    goToProjectPage() {
      this.$router.push({ name: "Projects" });
    }
  }
};
</script>

<style scoped>
.divider {
  border-top: 1px black solid;
}
</style>
